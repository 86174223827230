import { Button, Checkbox, Col, message, Row, Typography } from "antd";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { StockFormInitialValues, useStocksForm } from "../hooks/useStocksForm";
import { FormLabel } from "./FormLabel";
import { LimitInput } from "./inputs/LimitInput";
import { OrdertypeInput } from "./inputs/OrderTypeInput";
import { QuantityInput } from "./inputs/QuantityInput";
import { SideInput } from "./inputs/SideInput";
import { StockTickerAutocomplete } from "./inputs/StockTickerAutocomplete";
import { StopInput } from "./inputs/StopInput";
import { TIFInput } from "./inputs/TIFInput";
import { SendOutlined } from "@ant-design/icons";
import { TrailInput } from "./inputs/TrailInput";
import { StocksOrderConfirmationDialog } from "./StocksOrderConfirmationDialog";
import { useEffect, useState } from "react";
import api from "../../api";
import { UserRole } from "../../api/enums";
import { useWatchList } from "../../watchlist/context/watchlist-context";
import { useShareTradeContext } from "../context/share-trade-context";
import { useStocksEstimate } from "../hooks/useStocksEstimate";
import { formatMoney } from "../../shared/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
const { Text } = Typography;

interface TradeStocksProps {
  initialValues?: StockFormInitialValues;
  onSuccess?: () => void;
  syncWithWatchlist?: boolean;
  positionCloseMode?: boolean;
  onConfirmationModalVisibilityToggle?: (value: boolean) => void,
}

export const TradeStocks = ({ 
  initialValues, 
  onSuccess,
  syncWithWatchlist = false,
  positionCloseMode = false,
  onConfirmationModalVisibilityToggle,
}: TradeStocksProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { user }  = useAuthUser();
  const isAnalyst = user && user.role === UserRole.analyst;
  const { share, canShare, setShare } = useShareTradeContext()

  const {
    // form fields
    stock,
    setStock,
    orderType,
    setOrderType,
    side,
    setSide,
    tif,
    setTif,
    quantity,
    setQuantity,
    limitPrice,
    setLimitPrice,
    stopPrice,
    setStopPrice,
    trailType,
    setTrailType,
    trailValue,
    setTrailValue,
    outSideRegularTradingHours,
    setOutsideRegularTradingHours,

    // required fields related state
    limitRequired,
    stopRequired,
    trailRequired,
    showTif,

    // form related state
    showConfirmation,
    setShowConfirmation,
    handleSubmitClick,
    handleConfirmClick,
    submitting,
    error,

    // options for select
    options,

    limitPriceLoading,
  } = useStocksForm(initialValues);

  const { selected: selectedWatchlistItem } = useWatchList();

  useEffect(() => {
    if (!syncWithWatchlist || selectedWatchlistItem == null) {
      return
    }
    setStock(selectedWatchlistItem?.symbol)
  }, [selectedWatchlistItem, syncWithWatchlist]);

  useEffect(() => {
    if (onConfirmationModalVisibilityToggle) {
      onConfirmationModalVisibilityToggle(showConfirmation);
    }
  }, [onConfirmationModalVisibilityToggle, showConfirmation])

  const estimatedPrice = useStocksEstimate({
    quantity,
    limitPrice
  });

  const onConfirm = async () => {
    const response = await handleConfirmClick();
    // return if response is empty
    if (!response || !response.id) {
      return;
    }
    messageApi.success("Order Placed");
    if (onSuccess !== undefined) {
      onSuccess();
    }
    if (canShare && share) {
      await api.signals.share({ order_id: response.id });
    }
  };

  return (
    <div>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          {/* side  */}
          <SideInput
            options={options.sides.data}
            value={side}
            setValue={setSide}
          />
        </Col>

        {!!error && (
          <Col span={24}>
            <ErrorMessage style={{ margin: 0, marginTop: 8 }} error={error} />
          </Col>
        )}

        <Col span={24}>
          {/* stock */}
          <FormLabel>Stock</FormLabel>
          <StockTickerAutocomplete
            searchStocks={true}
            style={{ width: "100%" }}
            value={stock}
            placeholder="Stock"
            setValue={setStock}
            disabled={positionCloseMode}
          />
        </Col>

        <Col span={12}>
          <FormLabel>Order Type</FormLabel>
          <OrdertypeInput
            options={options.orderTypes.data}
            value={orderType}
            setValue={setOrderType}
          />
        </Col>

        <Col span={12}>
          <FormLabel>Quantity</FormLabel>
          <QuantityInput 
            value={quantity} 
            setValue={setQuantity} 
            precision={0}
            disabled={limitPriceLoading}
            loading={limitPriceLoading} 
          />
        </Col>

        {/* limit required */}
        {limitRequired && (
          <Col span={stopRequired ? 12 : 24}>
            <FormLabel>Limit Price</FormLabel>
            <LimitInput 
              disabled={limitPriceLoading}
              loading={limitPriceLoading}
              value={limitPrice} 
              setValue={setLimitPrice} />
          </Col>
        )}

        {/* stop required */}
        {stopRequired && (
          <Col span={limitRequired ? 12 : 24}>
            <FormLabel>Stop Price</FormLabel>
            <StopInput value={stopPrice} setValue={setStopPrice} />
          </Col>
        )}

        {/* trail required */}
        {trailRequired && (
          <Col span={24}>
            <FormLabel>Trail</FormLabel>
            <TrailInput
              trailTypeOptions={options.trailTypes.data}
              trailType={trailType}
              trailValue={trailValue}
              setTrailType={setTrailType}
              setTrailValue={setTrailValue}
            />
          </Col>
        )}

        {
          showTif && (
            <Col span={24}>
              {/* tif */}
              <FormLabel>Time In Force</FormLabel>
              <TIFInput
                options={options.timeInForces.data}
                value={tif}
                setValue={setTif}
              />
            </Col>
          )
        }

        {!!estimatedPrice && (
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text type="secondary">Estimated Price</Text>
              <Text strong>{formatMoney(estimatedPrice)}</Text>
            </div>
          </Col>
        )}


        <Col span={24}>
          <Checkbox
            value={outSideRegularTradingHours}
            onChange={(event) =>
              setOutsideRegularTradingHours(event.target.checked)
            }
          >
            Outside Regular Trading Hours
          </Checkbox>
        </Col>

        {
          canShare && <Col span={24}>
            <Checkbox
              checked={share}
              onChange={(event) =>
                setShare(event.target.checked)
              }
            >
              Share
            </Checkbox>
          </Col>
        }

        <Col span={24}>
          {/* submit button  */}
          <Button
            disabled={submitting}
            loading={submitting}
            onClick={handleSubmitClick}
            style={{ marginTop: 8 }}
            block
            size="large"
            type="primary"
          >
            <SendOutlined /> Place Order
          </Button>
        </Col>
      </Row>

      {/* options order confirmation dialog */}
      <StocksOrderConfirmationDialog
        onOk={onConfirm}
        open={showConfirmation}
        setOpen={setShowConfirmation}
        orderType={orderType}
        limitPrice={limitPrice}
        quantity={quantity}
        side={side}
        stock={stock}
        stopPrice={stopPrice}
        timeInForce={tif}
        trailType={trailType}
        trailValue={trailValue}
        outSideRegularTradingHours={outSideRegularTradingHours}
      />
    </div>
  );
};
