import { Card, Typography, Tag, Avatar, Space } from "antd";
import { SignalProviderListItem } from "../../api/signal-providers";
import { Link } from "react-router-dom";
import { SignalProviderTypeTag } from "./SignalProviderTypeTag";
import { getImageUrl } from "../../shared/image-utils";
import { PatternImage } from "../../shared/components/PatternImage";
import Flex from "../../shared/components/Flex";
import { getProfileImage } from "../utils/get-profile-image";

const SignalProviderListItemCard = ({ item }: { item: SignalProviderListItem }) => {
  const provider = item.signal_provider;

  const coverImage = provider.cover_image
    ? <img style={{ aspectRatio: `${16/9}` }} src={getImageUrl(provider.cover_image, { variant: '464x261' })} />
    : <PatternImage aspect={16 / 9} />

  return <Card
    style={{ overflow: 'clip', flex: 1, position: 'relative', background: 'transparent' }}
    cover={coverImage}
    bodyStyle={{ padding: '16px 8px 0 8px' }}
  >
    <Link
      style={{ position: 'absolute', inset: 0 }}
      to={`/app/signal-providers/${provider.username}`}></Link>
    <Flex flexDirection="column" gap={12}>
      <Flex gap={8} alignItems='center'>
        <Flex flexShrink={'0'}>
          <Avatar
            size={'large'}
            src={getProfileImage(provider, { variant: '100x100' })}
          >
            {!provider.profile_image && provider.name}
          </Avatar>
        </Flex>
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {provider.name}
          </Typography.Title>
          <Typography.Text type="secondary">
            @{ provider.username }
          </Typography.Text>
        </div>
      </Flex>
      <Flex flexDirection="column" gap={8}>
        <Space>
          <SignalProviderTypeTag type={provider.type} />
          {
            item.stocks && <Tag color="success">
              Stocks
            </Tag>
          }
          {
            item.options && <Tag color="success">
              Options
            </Tag>
          }
        </Space>
        <Typography.Paragraph type="secondary">
          {provider.intro}
        </Typography.Paragraph>
      </Flex>
    </Flex>
  </Card>
  // return (
  //   <Card style={{ width: '100%' }}>
  //       <Title level={3}>
  //         {signalProvider.signal_provider.name}
  //       </Title>
  //       <div style={{ margin: '16px 0' }}>   
  //         <SignalProviderTypeTag type={signalProvider.signal_provider.type} />   
  //         {
  //           signalProvider.stocks && <Tag color="success">
  //             Stocks
  //           </Tag>
  //         }
  //         {
  //           signalProvider.options && <Tag color="success">
  //             Options
  //           </Tag>
  //         }
  //       </div>
  //       <div style={{ marginTop: 20 }}>
  //         <Text type="secondary">Plans Starting At</Text>
  //         <Title style={{ margin: 0 }} level={4}>
  //           {formatMoney(signalProvider.starting_price, 2)}
  //         </Title>
  //       </div>
  //       <Paragraph style={{ marginTop: 20 }} type="secondary">
  //         { signalProvider.signal_provider.bio }
  //       </Paragraph>
  //       <Link
  //         to={`/app/signal-providers/${signalProvider.signal_provider.username}`}>
  //         <Button
  //           block
  //           size="large"
  //           type="primary"
  //         >
  //           See Subscriptions
  //         </Button>
  //       </Link>
  //   </Card>
  // );
};

export default SignalProviderListItemCard;
