import { SignalItem } from "../../api/signals";
import { useState } from "react";
import { AssetType, TimeInForce } from "../../api/enums";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { getMaxQuantityForSignal, overrideSignal, toPlaceOrderFormValues } from "../utils/signals";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const useSignalRowContext = (signal: SignalItem) => {

  const { user } = useAuthUser();
  const tradePerference = user?.trade_preference || null;

  const [copyModalState, setCopyModalState] = useState<{
    open: boolean;
    initialValues: PlaceOrderFormInitialValues | null;
  }>({
    open: false,
    initialValues: null
  });

  const [tif, setTif] = useState(tradePerference?.time_in_force || signal.time_in_force || TimeInForce.Day);
  const [price, setPrice] = useState<number | null>(signal.limit_price || signal.last_price || null);
  const [quantity, setQuantity] = useState<number>(getMaxQuantityForSignal(signal, tradePerference));
  const multiplier = signal.asset_type === AssetType.Options ? 100 : 1;
  const total = price !== null
    ? price * quantity * multiplier
    : null;

  const overriddenSignal = overrideSignal(signal, {
    price: price,
    quantity: quantity,
    tif: tif,
  });

  const copy = () => {

    const initialValues = toPlaceOrderFormValues(overriddenSignal);

    if (initialValues === null) {
      return;
    }

    setCopyModalState({
      open: true,
      initialValues: initialValues,
    });
  };

  const closeCopyModal = () => {
    setCopyModalState({
      open: false,
      initialValues: null,
    });
  };

  return {
    tif, setTif,
    price, setPrice,
    quantity, setQuantity,
    copyModal: {
      ...copyModalState,
      close: closeCopyModal,
    },
    total,
    copy,
    overriddenSignal
  };
};

