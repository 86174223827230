import { useEmotionCss } from "@ant-design/use-emotion-css"
import { Card, Tag, Typography } from "antd"
import { SignalProvider, SignalProviderWithMembershipPlans } from "../../api/signal-providers"
import Flex from "../../shared/components/Flex"
import { getProfileImage } from "../utils/get-profile-image"
import { SignalProviderTypeTag } from "./SignalProviderTypeTag"

export function SignalProviderBasicInfoCard({
  provider,
} : {
  provider: SignalProviderWithMembershipPlans,
}) {
  const offersStocks = (provider.membership_plans || []).find(plan => plan.stocks) !== undefined;
  const offersOptions = (provider.membership_plans || []).find(plan => plan.options) !== undefined;

  return <Card>
    <Flex gap={16}>
      <SignalProviderAvatar provider={provider}/>
      <Flex flexDirection="column" gap={8}>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          { provider?.name }
        </Typography.Title>
        <Typography.Text type="secondary" style={{ fontSize: '1rem' }}>@{ provider.username }</Typography.Text>
        <Flex gap={4} flexWrap='wrap'>
          <SignalProviderTypeTag type={provider.type} />
          {
            offersStocks && <Tag color="success">
              Stocks
            </Tag>
          }
          {
            offersOptions && <Tag color="success">
              Options
            </Tag>
          }
        </Flex>
      </Flex>
    </Flex>
  </Card>
}

function SignalProviderAvatar({ provider } : { provider: SignalProvider }) {
  const avatarClass = useEmotionCss(({ token }) => ({
    width: 80,
    height: 80,
    objectFit: 'cover',
    borderRadius: '100%',
    [`@media(min-width: ${token.screenLGMin})`]: {
      width: 100,
      height: 100,
    }
  }))

  return <img
    className={avatarClass}
    src={getProfileImage(provider, { variant: '100x100' })} 
  />
}